<script setup>
import AddStepButton from "./AddStepButton.vue";
import Steps from "../Step/Steps.vue";

const block = defineModel({
    block: { type: Object, required: true},
});
</script>

<template>
  <b v-if="block.after === 0">Immediately</b>
  <b v-else>After {{ block.after }} days</b>
  <Steps v-model="block.steps"/>
  <AddStepButton v-model="block.steps"/>
</template>
