<script setup>
import {ref, watch} from 'vue';
import Blocks from "./Block/Blocks.vue";
import SaveJourneyButton from "./SaveJourneyButton.vue";
import ResetJourneyButton from "./ResetJourneyButton.vue";

// TODO Validate props, see https://vuejs.org/guide/components/props.html#prop-validation
const props = defineProps({
    initialJourney: {
        type: Object,
        required: true,
    },
    saveUrl: {
        type: String,
        required: true,
    }
});

// Store the original initialJourney in a non-reactive variable
// This ensures we keep the original state regardless of what changes happen
const originalJourney = JSON.parse(JSON.stringify(props.initialJourney));

// The reactive journey state that will be modified by user interactions
const journey = ref({...props.initialJourney});

// Whether anything in the journey has been modified
const isDirty = ref(false);

watch(journey, () => {
    isDirty.value = true;
}, {deep: true});
</script>

<template>
  <div class="journey-actions">
    <SaveJourneyButton v-model:isDirty="isDirty" v-model:journey="journey" v-model:originalJourney="originalJourney" :saveUrl="saveUrl"/>
    <ResetJourneyButton v-model:isDirty="isDirty" v-model:journey="journey" :originalJourney="originalJourney"/>
  </div>
  <Blocks v-model="journey.blocks"/>
</template>
