<script setup>
const step = defineModel({
    type: Object,
    required: true,
});
</script>

<template>
    <div class="form__row">
      <div class="form__field">
        <label class="label" for="">Message</label>
        <input type="text" class="text-input" v-model="step.message">
      </div>
    </div>
</template>
