<script setup>
import { ref } from 'vue';

import Editor from "../../Editor/Editor.vue";

const step = defineModel({
    type: Object,
    required: true,
});

const language = ref('DE');
</script>

<template>
    <div style="display: flex; gap: 15px;margin-bottom: 15px;">
      <label><input type="radio" v-model="language" value="DE"/>German</label>
      <label><input type="radio" v-model="language" value="EN"/>English</label>
    </div>
    <div>
        <div v-show="language === 'DE'">
            <Editor v-model="step.body"/>
        </div>
        <div v-show="language === 'EN'">
            To be implemented
        </div>
    </div>
</template>
