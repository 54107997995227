<script setup>
import SendEmailStep from "./SendEmailStep.vue";
import NotifySuccessManagerStep from "./NotifySuccessManagerStep.vue";
import RemoveStepButton from "./RemoveStepButton.vue";

const step = defineModel({
    type: Object,
    required: true,
});
</script>

<template>
    <div class="journey-step">
        <b>{{ step.type }}</b>

        <div v-if="step.type === 'SendEmailStep'">
            <SendEmailStep v-model="step" />
        </div>
        <div v-else-if="step.type === 'NotifySuccessManagerStep'">
            <NotifySuccessManagerStep v-model="step" />
        </div>
        <RemoveStepButton @remove-step="$emit('removeStep')"/>
    </div>
</template>
